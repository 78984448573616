<style>
table.dataTable>tbody>tr.child ul.dtr-details { width:100%!important; }
</style>
<template>
	<div class="">
		<section class="content">
			<div class="container-fluid">
				<div class="row">
					<section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
						<div class="card">
							<div class="card-body table-responsive table-head">
								<div class="row bor-bot pb-3">
									<div class="col-md-7 pl-0">
										<h1 class="m-0 text-dark pt-2 pb-2">Merchandise Fulfillment Report</h1>
									</div>
									<div class="col-md-5">
										<div class="text-right pb-3">
											<ol class="breadcrumb float-sm-right">
												<li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
												<li class="breadcrumb-item"><Breadcrumbs/></li>
											</ol>
										</div> 
									</div>
								</div>
								
								<div class="float-right pb-3">
									<b-button class="btn-darkblue btn" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-fulfillment-filter><i class="fas fa-sliders-h"></i> Filters</b-button>
									<b-button class="ml-1 btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied"><i class="fas fa-redo"></i> Reset</b-button>
									<download-excel type="csv" :fetch="fetchTodos" :fields="btn.excel.fields" :before-generate="beforeGenerate" :before-finish="beforeFinish" name="Merchandise Fulfillment Report.xls" v-if="btn.excel.status == true" class="btn p-0">
										<button class="btn ml-1 btn-primary btn-darkblue btn btn-secondary"><i class="fas fa-file-download"></i> Export</button>
									</download-excel>
									<button disabled class="btn ml-1 btn btn-secondary disabled" v-if="btn.excel.status == false"><i class="fas fa-circle-notch fa-spin"></i> Generating Report</button>
								</div>
								<div class="table-responsive mt-3">
									<table class="table table-sm table-bordered table-hover" id="list_table">
										<thead class="thead-dark">
											<tr><th class="all">Order Date</th>
												<th class="all">Member #</th>
												<th class="all">First Name</th>
												<th class="all">Last Name</th>
												<th class="all">Address1</th>
												<th class="all">Address2</th>
												<th class="all">City</th>
												<th class="all">State</th>
												<th class="all">Zip</th>
												<th class="all">Email</th>
												<th class="all">Phone</th>
												<th class="all">Fulfilled</th>
												<th class="all">Fulfilled Date</th>
												<th class="all">Tracking ID</th>
												<th class="all">Subtotal</th>
												<th class="all">Shipping Cost</th>
												<th class="all">Total Paid</th>
												<th class="none">&nbsp;</th></tr>
										</thead>
										<tbody @click="handleClick"></tbody>
									</table>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</section>
		<b-modal id="modal-fulfillment-filter" v-bind:hide-footer="true" ref="modal-fulfillment-filter" size="md" title="Search Merchandise Fulfillment">
			<form ref="form" method="post" @submit.prevent="advancedSearch()">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="order_start_date" class="form-label">Order From Date</label>
							<div class="input-group birthdate">
								<div class="birthdaypicker">
									<date-pick
										v-model="searchForm.order_start_date"
										:format="'MM/DD/YYYY'"
										:displayFormat="'MM/DD/YYYY'"
										v-mask="'##/##/####'"
                    :parseDate="parseDatePick"
									></date-pick>
								</div>
							</div>
							<input id="order_start_date" type="hidden" class="form-control" :value="order_start_date_1">
							<div class="text-sm text-danger">{{ errors.order_start_date }}</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="order_end_date" class="form-label">Order To Date</label>
							<div class="input-group birthdate">
								<div class="birthdaypicker">
									<date-pick
										v-model="searchForm.order_end_date"
										:format="'MM/DD/YYYY'"
										:displayFormat="'MM/DD/YYYY'"
										v-mask="'##/##/####'"
                    :parseDate="parseDatePick"
									></date-pick>
								</div>
							</div>
							<input id="order_end_date" type="hidden" class="form-control" :value="order_end_date_1">
							<div class="text-sm text-danger">{{ errors.order_end_date }}</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label class="form-label">First Name</label>
							<b-form-input v-model="searchForm.first_name" id="firstname"></b-form-input>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label class="form-label">Last Name</label>
							<b-form-input v-model="searchForm.last_name" id="lastname"></b-form-input>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label class="form-label">Member #</label>
					<b-form-input v-model="searchForm.confirmation_code" name="confirmation_code" id="confirmation_code"></b-form-input>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<b-form-radio-group id="fulfilled" v-model="searchForm.fulfilled" name="fulfilled"  @change="fulfilled_date_enabled($event)">
								<b-form-radio :value="2">All</b-form-radio>
								<b-form-radio :value="1">Fulfilled</b-form-radio>
								<b-form-radio :value="0">Unfulfilled</b-form-radio>
							</b-form-radio-group>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label class="form-label">Fulfilled Date</label>
							<div class="birthdate" :class="{ 'bg-gray-light' : searchForm.fulfilled != 1 && searchForm.fulfilled_date_enable == false }">
								<div class="birthdaypicker">
									<date-pick
										v-model="searchForm.fulfilled_date"
										:format="'MM/DD/YYYY'"
										:displayFormat="'MM/DD/YYYY'"
										v-mask="'##/##/####'"
										:editable="searchForm.fulfilled == 1 && searchForm.fulfilled_date_enable == true"
                    :parseDate="parseDatePick"
									></date-pick>
								</div>
							</div>
							<input id="fulfilled_date" type="hidden" class="form-control" :value="fulfilled_date">
							<div class="text-sm text-danger">{{ errors.fulfilled_date }}</div>
						</div>
					</div>
				</div>
				<p class="text-center"><button type="submit" class="btn btn-primary">Search</button></p>
			</form>
		</b-modal>
		<b-modal id="modal-fulfillment-update" v-bind:hide-footer="true" ref="modal-fulfillment-update" size="md" title="Update Merchandise Fulfillment">
			<form ref="form" method="post" @submit.prevent="FulfillmentUpdate()">
				<div class="form-group">
					<div class="icheck-primary my-0">
						<b-form-checkbox id="fulfillment.fulfilled" name="fulfillment.fulfilled" v-model="fulfillment.fulfilled" value="1" v-on:change="fulfillment_date_enabled($event)">Fulfilled</b-form-checkbox>
					</div>
				</div>
				<div class="form-group">
					<label class="form-label">Fulfilled Date</label>
					<div class="birthdate" :class="{ 'bg-gray-light' : fulfillment.fulfilled != 1 }">
						<div class="birthdaypicker" :class="errors.update != undefined && errors.update.fulfilled_date.valid == false ? 'border-danger' : ''">
							<date-pick
								v-model="fulfillment.fulfilled_date"
								:format="'MM/DD/YYYY'"
								:displayFormat="'MM/DD/YYYY'"
								v-mask="'##/##/####'"
								:editable="fulfillment.fulfilled == 1"
                :parseDate="parseDatePick"
							></date-pick>
						</div>
					</div>
					<input name="fulfillment.fulfilled_date" id="fulfillment.fulfilled_date" type="hidden" class="form-control" :value="fulfillment.fulfilled_date" :class="[(errors.update != undefined && errors.update.fulfilled_date.valid == false ? 'is-invalid invalid' : '')]">
					<small class="invalid-feedback" v-if="errors.update != undefined && errors.update.fulfilled_date.valid == false">{{ errors.update.fulfilled_date.message }}</small>
				</div>
				<div class="form-group">
					<label class="form-label">Tracking ID</label>
					<b-form-input name="fulfillment.tracking_id" id="fulfillment.tracking_id" v-model="fulfillment.tracking_id" :class="[(fulfillment.fulfilled != 1 ? 'bg-light' : ''), (errors.update != undefined && errors.update.tracking_id.valid == false ? 'is-invalid invalid border-danger' : '')]" :value="fulfillment.tracking_id" :readonly="fulfillment.fulfilled != 1"></b-form-input>
					<small class="invalid-feedback" v-if="errors.update != undefined && errors.update.tracking_id.valid == false">{{ errors.update.tracking_id.message }}</small>
				</div>
				<p class="text-center"><button type="submit" class="btn btn-primary">Update</button></p>
			</form>
		</b-modal>
	</div>
</template>

<script>
import JsonExcel from "vue-json-excel";
import moment from 'moment';
import $ from "jquery";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

import DatePick from "vue-date-pick";
import axios from 'axios';
import VueToast from 'vue-toast-notification';

const validateName = name => {
if (name.length || name != "") {
	return { valid: true, error: null };
}
return { valid: false, error: null };
};

export default {
	name: "MerchandiseFulfillmentReport",
	data(){
		return {
			membershipData: [],
			table: {},
			errors: {},
			disciplines_list: [],
			filtersApplied: false,
			valid: false,
			searchForm: {
				order_start_date: '',
				order_end_date: '',
				first_name:'',
				last_name:'',
				confirmation_code:'',
				fulfilled:2, //0-Non Fulfilled, 1->Fulfilled, 2-All
				fulfilled_date:"",
				fulfilled_date_enable:false,
			},
			fulfillment:{
				id:'',
				fulfilled:0,
				fulfilled_date:'',
				tracking_id:''
			},
			btn:{excel:{status:true, fields: {
				'Order ID' : "id",
				'Order Date': "orderdate",
				'Member #': "member_id",
				'First Name' : "firstname",
				'Last Name' : "lastname",
				"Address 1" : "address1",
				"Address 2" : "address2",
				'City' : "city",
				'State' : "state",
				'Zip' : "zip",
				'Email-ID' : "email",
				'Phone' : "phone",
				'Fulfilled' : "fulfilled",
				'Fulfilled Date' : "fulfilled_date",
				'Fulfilled Trackin ID' : "tracking_id",
				'Item Description': 'product',
				'Style' : 'style',
				'Size' : 'size', 
				'Price' : 'price',
				'Quantity' : 'quantity',
				' ': 'finaltotal',
				'Total': 'total'
				}}
			},
		};
	},
	methods: {
		fulfillment_date_enabled: function(e) {
			if (e == 1) {
				this.fulfillment.fulfilled = 1;
				this.errors.update.fulfilled_date = { valid: true, message: '' };
				this.errors.update.tracking_id = { valid: true, message: '' };
			} else {
				this.fulfillment.fulfilled = 0;
				this.fulfillment.fulfilled_date = '';
				this.fulfillment.tracking_id = '';
			}
		},
		fulfilled_date_enabled: function(e) {
			if (e == 1) {
				this.searchForm.fulfilled = 1;
				this.searchForm.fulfilled_date_enable = true;
			} else {
				this.searchForm.fulfilled = (e == 0 ? 0 : 2);
				this.searchForm.fulfilled_date = '';
				this.searchForm.fulfilled_date_enable = false;
			}
		},
		loadFulfillmentData(){
			var self = this;
			this.table = $("#list_table").DataTable({
				sDom: "lrtip",
				processing: true,
				serverSide: true,
				pageLength: 10,
				"aLengthMenu": [[10, 50, 100, 250, 500, -1],
								[10, 50, 100, 250, 500, "All"]],
				bFilter: true,
				rowReorder: {
					selector: 'td:nth-child(0)'
				},
				"aaSorting": [],
				"order": [[ 0, 'desc' ], [ 3, 'asc' ], [ 2, 'asc' ]],
				responsive: true,
				ajax: {
					url: process.env.VUE_APP_URL + "api/reports/merchandise-fulfillment",
					type: 'post',
					data: function (d) {
						d.order_start_date = $("#order_start_date").val() || self.searchForm.order_start_date;
						d.order_end_date = $("#order_end_date").val() || self.searchForm.order_end_date;
						d.first_name = self.searchForm.first_name;
						d.last_name = self.searchForm.last_name;
						d.confirmation_code = self.searchForm.confirmation_code;
						d.fulfilled = (self.searchForm.fulfilled == 2 ? '' : self.searchForm.fulfilled);
						d.fulfilled_date = (self.searchForm.fulfilled_date_enable == true ? self.searchForm.fulfilled_date : '');
					}
				},
				columns: [
					{ data: 'created_at', name: "created_at", orderable: true, searchable: false },
					{ data: 'registration.member.confirmation_code', name: "", orderable: false },
					{ data: 'registration.personal_details.first_name', name: 'Registration.personal_details.first_name', orderable: true, searchable: false},
					{ data: "registration.personal_details.last_name", name: "Registration.personal_details.last_name", orderable: true, searchable: false },
					{ data: "registration.contact_details.address1", name: "Registration.contact_details.address1", orderable: false, class:"text-center" },
					{ data: "registration.contact_details.address2", name: "Registration.contact_details.address2", orderable: false, class:"text-center" },
					{ data: "registration.contact_details.city", name: "Registration.contact_details.city", class:"text-center" },
					{ data: "registration.contact_details.state_code", name: "Registration.contact_details.state_code", orderable: false, class:"text-center" },
					{ data: "registration.contact_details.zip", name: "Registration.contact_details.zip", orderable: false, class:"text-center" },
					{ data: "registration.contact_details.email", name: "Registration.contact_details.email", orderable: false, class:"text-center", render: (data)=>{return  '<a class="email-link" href="mailto:'+ data +'">'+ data +'</a>' || "N/A";} },
					{ data: "registration.contact_details.phone_1", name: "Registration.contact_details.phone_1", orderable: false, class:"text-center" },
					{ data: null, name: "fulfilled", orderable: true, class:"text-center", render: (data, type, full, meta) => {
						return (full.fulfilled != undefined && full.fulfilled == 1 ? 'Yes' : 'No') + '&nbsp;<a data-index="'+meta.row+'" class="btn btn-refersh edit_fulfillment"><i class="fas fa-edit"></i></a>'; } },
					{ data: null, name: "Registration.team_insurance.fulfilled_date", orderable: false, class:"text-center", render: (data, type, full, meta) => {
						return (full.fulfilled_date != undefined && full.fulfilled_date != '' ? this.formatDate(full.fulfilled_date) : 'N/A'); } },
					{ data: "tracking_id", name: "tracking_id", orderable: false, class:"text-center" },
					{ data: "sub_total", name: "sub_total", orderable: false, class:"text-right" },
					{ data: "shipping_cost", name: "shipping_cost", orderable: false, class:"text-right" },
					{ data: "total", name: "total", orderable: false, class:"text-right" },
					{ data: null, name: "", orderable: false, render: (data, type, full, meta) => {
						var ff = '<table class="table table-sm">'+
						'<thead><tr><th class="px-1" width="55%">Item Description</th><th class="px-1" width="5%">Style</th><th class="px-1" width="5%">Size</th><th class="px-1" width="10%">Unit Price</th><th class="px-1" width="10%">Qty</th><th class="px-1" width="10%">Total</th></tr></thead><tbody>';
						if(full.merchandise_carts.length > 0) {
							$.each(full.merchandise_carts, function(i, v) {
								let vari = { 'color':'', 'size': '' };
								$.each(v.cart_variants, function(vi, vv) {
									if(vv.merchandise_product_variant.name == 'Size') { vari.size = vv.name; }
									if(vv.merchandise_product_variant.name == 'Color') { vari.color = vv.name; }
								});
								ff += '<tr><td>'+v.name+'</td><td>'+vari.color+'</td><td>'+vari.size+'</td><td class="text-right">'+v.price+'</td><td class="text-right">'+v.quantity+'</td><td class="text-right">'+v.total+'</td></tr>';
							});
						ff += '</tbody><tfoot class="font-weight-bold"><tr><td colspan="5">Sub Total</td><td class="text-right">'+full.sub_total+'</td></tr></tfoot>';
						} else { ff += '<tr><td colspan="12" class="text-center">No products purchased</td></tr>'; }
						ff += '</table>';
						return ff;
					} },
				]
			});
		},
		advancedSearch() {
			if(!this.validate()) {
				return;
			}
			this.table.draw();
			this.filtersApplied = true;
			this.$refs['modal-fulfillment-filter'].hide();
		},
		validate(){
			this.errors = {}
			this.valid =  true;
			const validTStartDate = validateName(this.searchForm.order_start_date);
			this.errors.order_start_date = validTStartDate.error;

			const validTEndDate = validateName(this.searchForm.order_end_date);
			this.errors.order_end_date = validTEndDate.error;
			
			let dateValid = false;
			if(validTStartDate.valid && !this.validateDate(this.searchForm.order_start_date)){
				dateValid = true;
				this.errors.order_start_date = "Invalid Date";
			}
			if(validTEndDate.valid && !this.validateDate(this.searchForm.order_end_date)){
				dateValid = true;
				this.errors.order_end_date = "Invalid Date";
			}

			if(dateValid == false){
				if((validTStartDate.valid && validTEndDate.valid) && (moment(this.searchForm.order_end_date).isBefore(this.searchForm.order_start_date))) {
					this.errors.order_end_date = 'End date must be greater than Start date!';
					return this.valid =  false;
				} else {
					this.valid = true;
				}
			} else {
				this.valid = false;
			}

			return this.valid;
		},
		refreshList(event){
			this.searchForm = {
				order_start_date: '',
				order_end_date: '',
				sort_by: '',
				order_by: ''
			};
			this.table.ajax.reload();
			this.filtersApplied = false;
		},
		formatDate(value) {
			var date=  moment(value, "YYYY-MM-DD hh:mm:ss");
			if(date.isValid()){
				return date.format('MM/DD/YYYY');
			} else {
				return 'N/A';
			}
		},
		async fetchTodos(){
			let filterdata = { order_start_date: $("#order_start_date").val() || this.searchForm.order_start_date,
								order_end_date: $("#order_end_date").val() || this.searchForm.order_end_date };
			let param = this.table.ajax.params();

			param.start = 0;
			param.length = -1;

			const response = await axios.post(process.env.VUE_APP_URL + "api/reports/merchandise-fulfillment/export/excel",  param);

			let mar = [];
			$.each(response.data.data, function(i, v) {
				if(v.merchandise_carts.length > 0) {
					$.each(v.merchandise_carts, function(mci, mcv) {
						let vari = { 'color':'', 'size': '' };
						$.each(mcv.cart_variants, function(vi, vv) {
							if(vv.merchandise_product_variant.name == 'Size') { vari.size = vv.name; }
							if(vv.merchandise_product_variant.name == 'Color') { vari.color = vv.name; }
						});
						mar.push({"id": (mci == 0 ? v.id : ''),
								"orderdate": (mci == 0 ? (v.created_at != '' && v.created_at != null ? v.created_at : '') : ''),
								"member_id": (mci == 0 ? v.registration.member.confirmation_code : ''),
								"firstname": (mci == 0 ? v.registration.member.personal_details.first_name : ''),
								"lastname": (mci == 0 ? v.registration.member.personal_details.last_name : ''),
								"address1": (mci == 0 ? v.registration.contact_details.address1 : ''),
								"address2": (mci == 0 ? v.registration.contact_details.address2 : ''),
								"city": (mci == 0 ? v.registration.contact_details.city : ''),
								"state": (mci == 0 ? v.registration.contact_details.state_code : ''),
								"zip": (mci == 0 ? v.registration.contact_details.zip : ''),
								"email": (mci == 0 ? v.registration.contact_details.email : ''),
								"phone": (mci == 0 ? v.registration.contact_details.phone_1 : ''),
								"fulfilled": (mci == 0 ? (v.fulfilled != undefined && v.fulfilled == 1 ? 'Yes' : 'No') : ''),
								"fulfilled_date":  (mci == 0 ? (v.fulfilled_date != undefined && v.fulfilled_date != '' ? moment(v.fulfilled_date, "YYYY-MM-DD HH:mm:ss").format('MM/DD/YYYY') : 'N/A') : ''),
								"tracking_id": (mci == 0 ? (v.tracking_id != undefined && v.tracking_id != '' ? v.tracking_id : 'N/A') : ''),
								'product': mcv.name,
								'style': vari.color,
								'size': vari.size,
								'price': mcv.price,
								'quantity': mcv.quantity,
								'finaltotal': '',
								'total': mcv.total });
					});
				} else {
					mar.push({"id": v.id,
							"orderdate": (v.created_at != '' && v.created_at != null ? v.created_at : ''),
							"member_id": v.registration.member.confirmation_code,
							"firstname": v.registration.member.personal_details.first_name,
							"lastname": v.registration.member.personal_details.last_name,
							"address1": v.registration.contact_details.address1,
							"address2": v.registration.contact_details.address2,
							"city": v.registration.contact_details.city,
							"state": v.registration.contact_details.state_code,
							"zip": v.registration.contact_details.zip,
							"email": v.registration.contact_details.email,
							"phone": v.registration.contact_details.phone_1,
							"fulfilled": (v.fulfilled != undefined && v.fulfilled == 1 ? 'Yes' : 'No'),
							"fulfilled_date":  (v.fulfilled_date != undefined && v.fulfilled_date != '' ? moment(v.fulfilled_date, "YYYY-MM-DD").format('MM/DD/YYYY') : 'N/A'),
							"tracking_id": (v.tracking_id != undefined && v.tracking_id != '' ? v.tracking_id : 'N/A'),
							'product':'',
							'style':'',
							'size':'',
							'price': '',
							'quantity': '',
							'finaltotal': '',
							'total': '' });
				}
				mar.push({"id": '', "orderdate": '', "member_id": '', "firstname": '', "lastname": '', "address1": '', "address2": '', "city": '', "state": '', "zip": '', "email": '', "phone": '', 'fulfilled': '', 'fulfilled_date': '', "tracking_id": '', 'product':'', 'style':'', 'size':'', 'price': '', 'quantity': '', 'finaltotal': 'Subtotal', 'total': v.sub_total });
				mar.push({"id": '', "orderdate": '', "member_id": '', "firstname": '', "lastname": '', "address1": '', "address2": '', "city": '', "state": '', "zip": '', "email": '', "phone": '','fulfilled': '', 'fulfilled_date': '', "tracking_id": '', 'product':'', 'style':'', 'size':'', 'price': '', 'quantity': '', 'finaltotal': 'Shipping Cost', 'total': v.shipping_cost});
				mar.push({"id": '', "orderdate": '', "member_id": '', "firstname": '', "lastname": '',"address1": '', "address2": '', "city": '', "state": '', "zip": '', "email": '', "phone": '', 'fulfilled': '', 'fulfilled_date': '', "tracking_id": '', 'product':'', 'style':'', 'size':'', 'price': '', 'quantity': '', 'finaltotal': 'Total Paid', 'total': v.total});
				mar.push({"id": '', "orderdate": '', "member_id": '', "firstname": '', "lastname": '', "address1": '', "address2": '', "city": '', "state": '', "zip": '', "email": '', "phone": '', 'fulfilled': '', 'fulfilled_date': '', "tracking_id": '', 'product':'', 'style':'', 'size':'', 'price': '', 'quantity': '', 'finaltotal': '', 'total': ''});
			});
			return mar;
		},
		beforeGenerate(){
			this.btn.excel.status=false;
		},
		beforeFinish(){
			this.btn.excel.status=true;
		},
		handleClick(e) {
			e.preventDefault();
			if (e.target.matches('.edit_fulfillment, .edit_fulfillment *')) {
				var ind = (e.target.tagName == 'A' ? e.target.dataset.index : (e.target.tagName == 'I' ? e.target.parentNode.dataset.index : ''));
				if(ind != '') {				
					var row = $("#list_table").DataTable().row(":nth("+ind+")").data();
					
					this.fulfillment = {
						id: row.id,
						fulfilled: (row.fulfilled != undefined && row.fulfilled == 1 ? 1 : 0),
						fulfilled_date: (row.fulfilled_date != undefined && row.fulfilled_date != '' ? this.formatDate(row.fulfilled_date) : ''),
						tracking_id: (row.tracking_id == '' || row.tracking_id == null ? '' : row.tracking_id)
					};
					
					this.errors = {
						update: {
							fulfilled_date : { valid : true, message : '' },
							tracking_id : { valid : true, message : '' }
						}
					};
					this.$root.$emit("bv::show::modal", "modal-fulfillment-update");
				}
				return false;
			}
		},
		async FulfillmentUpdate() {
			if(this.fulfillment.fulfilled == 1) {
				if(this.fulfillment.fulfilled_date == '') { this.errors.update.fulfilled_date = { valid: false, message: 'Select Fulfilled date' }; }
				else { this.errors.update.fulfilled_date = { valid: true, message: '' }; }

				if(this.fulfillment.tracking_id == '' || this.fulfillment.tracking_id == null) { this.errors.update.tracking_id = { valid: false, message: 'Enter Tracking ID' }; }
				else {  this.errors.update.tracking_id = { valid: true, message: '' }; }
			}
			if(this.errors.update.fulfilled_date.valid == true && this.errors.update.tracking_id.valid == true) {
				const response = await axios.post(process.env.VUE_APP_URL + "api/reports/merchandise-fulfillment/fulfillment/update", this.fulfillment);
				console.log(response);
				if(response.data.status == 'success') {
					this.errors = {};
					this.fulfillment = { id: '', fulfilled: 0, fulfilled_date: '', tracking_id: '' };
					this.$root.$emit("bv::hide::modal", "modal-fulfillment-update");
					this.table.ajax.reload();
				}
				alert(response.data.message);
			}
		}
	},
	mounted() {
		this.loadFulfillmentData();

		const retry = setInterval(() => {
			this.disciplines_list = this.getSiteProps('memberRegistration.individual.fields.donation.disciplines.options');
			if(this.disciplines_list) clearInterval(retry);
		}, 200);
	}
}
</script>